import React from "react";

import Navbar from "components/Navbar/Navbar";
import Hero from "components/Hero/Hero";

//import BackgroundImage from "assets/BackgroundLines.svg";

function App() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#000000",
          //backgroundImage: `url(${BackgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          // height: "100vh",
        }}>
        {/* <Navbar /> */}
        <Hero />
      </div>
    </>
  );
}

export default App;

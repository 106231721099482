import React from "react";

// import Button from "components/Button/Button";

import wordmark from "assets/wordmark.svg";

// import { IoLogoInstagram, IoLogoFacebook, IoLogoTwitter } from "react-icons/io";

const Hero = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center">
      {/* <div className="text-[24px] text-center font-bold tracking-tight px-6 mb-6 tablet:px-12 laptopL:text-[64px] laptop:text-[48px] tablet:text-[32px]">
        Design and development shop derived from FLYJETS’ dev team experience.
      </div> */}
      <img
        src={wordmark}
        alt="flylab-wordmark"
        className="mb-8 w-[256px] mobileM:w-[320px] tablet:w-[512px]"
      />
      <div className="text-[16px] text-center px-6 mb-4 tablet:text-[24px]">
        Design and Development Lab
      </div>
      {/* <Button label="contact us" /> */}
      <a
        className="hover:bg-zinc-900 ring-2 ring-zinc-800 rounded-full cursor-pointer transition-colors"
        href="mailto:FLY@flyjets.com">
        <div className="text-[12px] font-bold uppercase py-4 px-16 tablet:text-[16px] tablet:px-24">
          contact us
        </div>
      </a>
      {/* <div className="absolute bottom-[24px] left-[24px]">
        <div className="flex flex-row">
          <IoLogoInstagram className="w-8 h-8 mr-4 cursor-pointer" />
          <IoLogoFacebook className="w-8 h-8 mr-4 cursor-pointer" />
          <IoLogoTwitter className="w-8 h-8 mr-4 cursor-pointer" />
        </div>
      </div> */}
    </div>
  );
};

export default Hero;
